
<div class="container">
    
    <a class="marqueee  float-start mt-5" href="/blog-details">
        <marquee class="" style="border-top: 1px solid #f5f6f7; border-bottom: 1px solid #f5f6f7; ">
            <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>&nbsp;&nbsp;Cultivating Excellence with Organic Pesticides!
            <a href="/blog-details">
                <span style="color:#9a258b;"> &nbsp;  Papaya </span>,
                <span style="color:#9a258b;"> Red Chill </span>,
                <span style="color:#9a258b;"> Capsicum</span>,
                <span style="color:#9a258b;"> Tomato</span>,
                <span style="color:#9a258b;"> Cotton</span>
            </a>. &nbsp; Experience the future of farming with YK Laboratories. We're proud to introduce our pioneering approach to cultivating Papaya , Red Chill,Capsicum,Tomato and Cotton using 100% Organic Pesticides.
            <span style="color: #9a258b;">
                <i class="fas fa-angle-right"></i><i class="fas fa-angle-right"></i> Say NO to Harmful Chemicals!
                <i class="fas fa-angle-right"></i><i class="fas fa-angle-right"></i> Say YES to Sustainable Agriculture!
                <i class="fas fa-angle-right"></i><i class="fas fa-angle-right"></i> Say YES to Healthier Produce!
            </span>
            Join us in leading the way towards a greener, healthier future. Together, we're setting new standards in farming and food safety. <span style="color: #9a258b;">Cultivate with Care, Cultivate with YK Laboratories!  </span>
            <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
        </marquee><br /><br />
    </a>

</div>


<div class="container ">
   <br /> <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="intro-header" style="text-align:center">
                <h3 class="mt-1">Welcome To YK-Laboratories</h3><br /><br />
                <div class="text_div">
                    YK Laboratories is a renowned manufacturer of certified organic inputs, specializing in Botanical Virucides and Fungicides
                    in water-based formulations, as well as Botanical Insecticides and Sucking Pest Oils in oil-based formulations.
                </div>
            </div>
        </div>
    </div>
    <div class="banner">
        <h1 class="heading">About YK Laboratories</h1>
    </div>
    <div class="offset-wrap">
        <div class="content-card">
            <p>
                <b> We YK Laboratories manufacturing of certified organic inputs especially Botanical Virucide,</b> Fungicide water
                base and Botanical Insecticide & Sucking pest oil base, with the aid of Medicinal plants and related alkaloids
                which controls and suppress the multiplication of Bacterial and Fungal growth.
            </p>
            <p>
                We have developed the capability to match international quality standards stringent user requirements through
                continuous Field trials on my YK Farm Land which we cultivate organically all vegetables and flowers and cereals,
                horticulture crops, located in Hyderabad, INDIA. It improves the product quality and batch to batch consistency
                and reproducibility according to the climatic conditions.
            </p>
        </div>

    </div>

    <div class="about">
        <div class="container">
            <div class="section intro" style="height:auto">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card"style="background-color:#6b9426">
                                <div class="row">
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-10">
                                        <img src="../../../../assets/img/NewImages/New/chilli (1).webp" alt="yklabs" class="intro_img" />
                                        <h5 style="color: white;">YK Laboratories R & D</h5>
                                    </div> 
                                    <div class="col-lg-1"></div>
                                </div>
                             
                                <p style="color:white;">
                                    where research and development take center stage. Our
                                    dedicated team of scientists is constantly exploring innovative solutions in
                                    various fields, including chilli, papaya, nursery, and hi-tech nursery.<br /><br /><br />
                                </p>
                                <div><a href=""><i class="fa fa-arrow-right" style="color:white;float:right"></i></a></div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-6">
                            <h4 style="text-align: center;">Our History <br /></h4><br />
                            <p style="color:black">
                                Our products are derived from medicinal plants and contain beneficial alkaloids that effectively control and suppress the multiplication of bacterial and fungal growth.
                            </p>
                        </div>
                        <div class="col-lg-4 col-xs-6">      
                            <div class="card" style="background-color:#b87c49">

                                <div class="row">
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-10">
                                        <img src="../../../../assets/img/NewImages/New/chemical-free.webp" alt="yklabs" class="intro_img" />
                                        <h5 style="color: white;">Organic Pesticides</h5>
                                    </div>
                                    <div class="col-lg-1"></div>
                                </div>                              
                                <p>Our 100% natural solution ensures that you can achieve remarkable outcomes while staying true to your commitment to sustainable farming practices.<br /><br /><br /></p>
                                <div><a href=""><i class="fa fa-arrow-right" style="color:white;float:right"></i></a></div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-6">
                            <div class="card" style="background-color:#fe412c">
                                <div class="row">
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-10">
                                        <img src="../../../../assets/img/NewImages/New/plant-nursery (1).webp" alt="yklabs" class="intro_img" style="height:45px" />
                                        <h5 style="color: white;">Hi-Tech Nursery</h5>
                                    </div>
                                    <div class="col-lg-1"></div>
                                </div>
                                <p>Our scientifically formulated products guarantee healthier plants, higher yields, and superior quality, empowering you to achieve excellence in agriculture. <br /><br /><br /><br /></p>
                                <div><a href=""><i class="fa fa-arrow-right" style="color:white;float:right;margin-top: -9%;"></i></a></div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-6">
                            <img src="../../../../assets/img/NewImages/category1.jpg" class="card_img1"/>
                        </div>
                        <div class="col-lg-4 col-xs-6">
                            <div class="card" style="background-color:#135e00">
                                <div class="row">
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-10">
                                        <img src="../../../../assets/img/NewImages/New/product.webp" alt="yklabs" class="intro_img" />
                                        <h5 style="color: white;">Organic Products</h5>
                                    </div>
                                    <div class="col-lg-1"></div>
                                </div>
                                <p>We are proud to offer high-quality organic inputs that promote sustainable agriculture and contribute to the overall well-being of our customers and the environment.<br /><br /><br /></p>
                                <div><a href=""><i class="fa fa-arrow-right" style="color:white;float:right"></i></a></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      

    </div>

</div>

